import React, { FC, useEffect } from 'react';
import { PageLayout, JewelleryContent, CTALink, Banner } from 'components';
import { useStaticQuery, graphql } from 'gatsby';
import { LionIcon } from 'assets/tsx/lion-icon';

const query = graphql`
  query JewelleryPageQuery {
    jewelleryPage: allStrapiJewelleryPage {
      edges {
        node {
          id
          title
          description
          callToAction {
            link
            title
          }
          seo {
            metaDescription
            metaTitle
            shareImage {
              publicURL
              childImageSharp {
                gatsbyImageData(width: 400, height: 400)
              }
            }
          }
          banner {
            id
            title
            image {
              publicURL
              extension
              childImageSharp {
                fluid(maxWidth: 3500) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
          quotation {
            content
          }
        }
      }
    }
  }
`;
const Jewellery: FC = () => {
  const data = useStaticQuery(query);
  const jewelleryPageQuery = data.jewelleryPage.edges[0].node;
  const {
    title,
    description,
    seo,
    callToAction: { link, title: callToActionTitle },
    quotation: { content: quotation },
  } = jewelleryPageQuery;

  useEffect(() => {
    document.body.classList.add('jewellery-page');
    return () => {
      document.body.classList.remove('jewellery-page');
    };
  }, []);

  return (
    <PageLayout
      title={title}
      quotation={quotation}
      icon={<LionIcon />}
      metaTitle={seo?.metaTitle ?? title}
      description={seo?.metaDescription}
      image={seo?.shareImage?.publicURL}
      className="container"
    >
      <div className="jewellery-page">
        <h4 className="page-title"> &gt; {title}</h4>
        <div className="page-description">
          <p>{description}</p>
        </div>
        <div className="hero-banner jewellery-page-wrapper">
          <div className="hero-banner-slider">
            <Banner
              PageBanner={jewelleryPageQuery}
              BannerLength={jewelleryPageQuery.banner.length}
            />
          </div>
        </div>
        <JewelleryContent />
        <CTALink to={link}>{callToActionTitle}</CTALink>
      </div>
    </PageLayout>
  );
};

export default Jewellery;
